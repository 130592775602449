<template>
  <div class="nes-field">
        <label :for="selectName">
            {{ selectLabel }}
        </label>
        <div class="nes-select">
            <select
                :id="selectName"
                @change="$emit('security-updated', $event.target.value)"
            >
                <option
                    v-for="option in selectOptions"
                    :key="option"
                    :value="option"
                >
                    {{ option }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
  name: "Select",
  props: {
    selectLabel: {
      type: String,
      required: true,
    },
    selectName: {
      type: String,
      required: true,
    },
    selectOptions: {
      type: Array,
      required: true,
    },
  }
}
</script>