<template>
    <div class="nes-field">
        <label :for="inputName">
            {{ inputLabel }}
        </label>
        <input
            v-model.trim="value"
            class="nes-input"
            :type="inputType"
            :name="inputName"
            :placeholder="inputPlaceholder === undefined ? inputLabel : inputPlaceholder"
            @input="$emit('input-updated', $event.target.value)"
        />
    </div>
</template>

<script>
export default {
  name: "Input",
  props: {
    inputLabel: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
    inputPlaceholder: {
      type: String,
    },
    inputType: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {
      value: null,
    };
  }
}
</script>

<style scoped>
input {
  margin-bottom: 20px;
}
</style>