<template>
    <div id="app">
        <div class="container">
            <div class="row">
                <div class="col-12 offset-lg-2 col-lg-8">
                    <h1 class="text-center">WiFi QR Code Generator</h1>
                    <div class="nes-container with-title">
                      <p class="title">Instructions</p>
                      <div class="nes-container is-dark is-centered">
                        <span class="nes-text is-error">DO NOT scan the default QR Code !</span>
                      </div>
                      <div class="lists">
                        <ul class="nes-list is-disc">
                          <li>Type in your WiFi network credentials</li>
                          <li>Scan the QR Code</li>
                        </ul>
                      </div>
                      <div class="nes-container is-dark is-centered">
                        <span class="nes-text is-success">Your information is safe. There is no communication with a remote server.</span>
                      </div>
                    </div>
                </div>
                <div class="col-12 offset-lg-2 col-lg-4">
                    <div class="nes-container with-title is-centered">
                        <p class="title">QR Code</p>
                        <qrcode-vue :value="value" level="H" :size="size"/>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="nes-container with-title">
                        <p class="title">WiFi credentials</p>
                        <input-component
                            :input-name="`ssid`"
                            :input-label="`Network name`"
                            @input-updated="updateSsid($event)"
                        />
                        <input-component
                            :input-name="`password`"
                            :input-label="`Password`"
                            @input-updated="updatePassword($event)"
                        />
                        <select-component
                            :select-name="`security`"
                            :select-label="`Security`"
                            :select-options="[
                                'WEP',
                                'WPA',
                                'nopass',
                            ]"
                            @security-updated="updateSecurity($event)"
                        />
                    </div>
                </div>
                <div class="col-12 offset-lg-2 col-lg-8">
                    <div class="nes-container with-title">
                        <p class="title">Credits</p>
                        <p>
                            Written by David Ung
                            <a href="https://github.com/ungdavid" target="_blank">
                              <i class="nes-icon github is-medium"></i>
                            </a>
                            &nbsp;
                            <a href="https://www.linkedin.com/in/davidung2/" target="_blank">
                              <i class="nes-icon linkedin is-medium"></i>
                            </a>
                            using
                        </p>
                        <p>
                            <a href="https://vuejs.org/" target="_blank" class="nes-badge">
                              <span class="is-success">Vue.js</span>
                            </a>
                            &nbsp;
                            <a href="https://getbootstrap.com/" target="_blank" class="nes-badge">
                              <span class="is-primary">Bootstrap</span>
                            </a>
                            &nbsp;
                            <a href="https://github.com/nostalgic-css/NES.css" target="_blank" class="nes-badge">
                              <span class="is-dark">NES.css</span>
                            </a>
                            &nbsp;
                            <a href="https://github.com/scopewu/qrcode.vue" target="_blank" class="nes-badge">
                              <span class="is-error">qrcode.vue</span>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputComponent from './components/input';
import SelectComponent from './components/select';
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'App',
  components: {
    InputComponent,
    SelectComponent,
    QrcodeVue,
  },
  data() {
    return {
      value: 'https://youtu.be/dQw4w9WgXcQ',
      size: 0,
      ssid: '',
      password: '',
      security: '',
    };
  },
  created() {
    this.size = window.innerWidth < 500 ? 200 : 340;
  },
  methods: {
    updateSsid(value) {
      this.ssid = value;
      this.generateQrCode();
    },
    updatePassword(value) {
      this.password = value;
      this.generateQrCode();
    },
    updateSecurity(value) {
      this.security = value;
      if (this.security === 'nopass') {
        this.password = '';
      }
      this.generateQrCode();
    },
    generateQrCode() {
      this.value = `WIFI:T:${this.security};S:${this.ssid};P:${this.password};;`;
    },
  }
}
</script>

<style>
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/nes.css/css/nes.min.css";

#app {
  font-family: 'Press Start 2P', cursive;
  margin-top: 60px;
}
h1, .nes-container {
  margin-bottom:40px;
}
.nes-list {
  margin-top: 1rem;
}
</style>
